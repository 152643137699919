import axios from "@/libs/axios";

const state = {
    expertsTopics: null,
    languages: [],
    targetGroups: null
}

const mutations = {
    SET_EXPERTS_TOPICS: (state, expertsTopics) => {
        state.expertsTopics = expertsTopics;
    },

    SET_LANGUAGES: (state, languages) => {
        state.languages = languages;
    },
    SET_TARGET_GROUPS: (state, targetGroups) => {
        state.targetGroups = targetGroups;
    },


}
const actions = {


    GET_EXPERTS_TOPICS: (context, payload) => {
        let url = `experttopics`
        return axios.get(url, payload).then(response => {
            context.commit('SET_EXPERTS_TOPICS', response.data.items);
        })

    },

    GET_LANGUAGES: (context, payload) => {
        let url = `/languages`
        return axios.get(url).then(response => {
            context.commit('SET_LANGUAGES', response.data.items);

        })
    },
    GET_TARGET_GROUPS: (context, payload) => {
        let url = `/targetgroups`
        return axios.get(url).then(response => {
            context.commit('SET_TARGET_GROUPS', response.data.items);
        })
    },


}

const getters = {
    EXPERTS_TOPICS: state => {
        return state.expertsTopics;
    },
    LANGUAGES: state => {
        return state.languages
    },
    TARGET_GROUPS: state => {
        return state.targetGroups
    },

}
export default {
    state, getters, mutations, actions
}
