export default [
    {
        path: '/event-room/show/:booking_id',
        name: 'event-room-show',
        component: () => import('@/views/event-room/EventRoomShow.vue'),
        meta: {
            resource: 'auth', 
            layout: 'event-room',

        }
    },
]


