export default [
    // {
    //     path: '/events',
    //     name: 'event-show',
    //     component: () => import('@/views/events/EventDetailsCard'),
    // },

    {
        path: '/events/fixed',
        name: 'events-list-fixed',
        component: () => import('@/views/events/EventList/EventsListFixed.vue'),
        meta: {
            resource: 'auth',
            breadcrumb: [{
                text: 'Fixed Events',
                to: '/events/fixed'
            },
            ],
            pageTitle: 'Show Fixed Events'
        },
    },



    {
        path: '/events/dynamic',
        name: 'events-list-dynamic',
        component: () => import('@/views/events/EventList/EventsListDynamic.vue'),
        meta: {
            resource: 'auth',
            breadcrumb: [{
                text: 'Dynamic Events',
                to: '/events/dynamic'
            },
            ],
            pageTitle: 'Show Dynamic Events'
        },
    },





    {
        path: '/event/:eventid',
        name: 'event-details',
        component: () => import('@/views/events/event-single/EventsShow.vue'),
        meta: {
            resource: 'auth',
            breadcrumb: [{
                text: 'Events',
                to: '/events'
            },
                {
                    text: 'Event Details',
                    active: true
                },
            ],
            pageTitle: 'Event Details'
        },
    },
]


