export default [
    {
        path: '/payment-methods',
        name: 'payment-method-list',
        component: () => import('@/views/admin/payment-methods/PaymentMethodList.vue'),
        meta: {
            breadcrumb: [

                {
                    text: 'Payment Methods',
                    active: true
                }
            ],
            pageTitle: 'Payment Methods',
            navActiveLink: 'payment-methods',
            resource: 'auth',
            action: 'manage',
        },
    },

    {
        path: '/payment-methods/add',
        name: 'payment-methods-add',
        component: () => import('@/views/admin/payment-methods/PaymentMethodAdd.vue'),
        meta: {
            resource: 'auth',
            action: 'manage',
            breadcrumb: [
                {
                    text: 'Payment Methods',
                    to: '/payment-methods',
                },
                {
                    text: 'Add Payment Method',
                    active: true
                }
            ],
            pageTitle: 'Add Payment Method',
            navActiveLink: 'payment-methods',
        },
    },
]


