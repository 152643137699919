export default [

    {
        path: '/bookings/past',
        name: 'bookings-list-past',
        component: () => import('@/views/bookings/ExpertsBookingsListPast.vue'),
        meta: {
            resource: 'auth',
            breadcrumb: [{
                text: 'Past Bookings',
                to: '/bookings/past'
            },
            ],
            pageTitle: 'Past Bookings'
        },
    },
    {
        path: '/bookings/upcoming',
        name: 'bookings-list-upcoming',
        component: () => import('@/views/bookings/ExpertsBookingsListUpcoming.vue'),
        meta: {
            resource: 'auth',
            breadcrumb: [{
                text: 'Upcoming Bookings',
                to: '/bookings/upcoming'
            },
            ],
            pageTitle: 'Upcoming Bookings'
        },
    },




    {
        path: '/bookings/reschedule',
        name: 'bookings-reschedule',
        component: () => import('@/views/bookings/EventBookingsReschedule.vue'),
        meta: {
            resource: 'auth',
            breadcrumb: [{
                text: 'My Bookings',
                to: '/bookings/show'
            },
                {
                    text: 'Reschedule My Booking',
                    active: true
                },
            ],
            pageTitle: 'Reschedule My Booking'
        },
    },


    {
        path: '/booking/:bookingid',
        name: 'booking-details',
        component: () => import('@/views/bookings/BookingDetails.vue'),
        meta: {
            resource: 'auth',
            breadcrumb: [{
                text: 'My Bookings',
                to: '/bookings/'
            },
                {
                    text: 'Booking details',
                    active: true
                },
            ],
            pageTitle: 'Booking details'
        },
    },


    {
        path: '/booking/:bookingid/cancellation',
        name: 'booking-cancellation',
        component: () => import('@/views/bookings/BookingCancellation.vue'),
        meta: {
            resource: 'auth',
            breadcrumb: [{
                text: 'My Bookings',
                to: '/bookings/'
            },
                {
                    text: 'Booking cancellation',
                    active: true
                },
            ],
            pageTitle: 'Booking cancellation'
        },
    },

]


