import axios from "@/libs/axios";


const state = {
    expertVideo: null,
}

const mutations = {
    SET_VIDEO_NUGGETS: (state, expertVideo) => {
        state.expertVideo = expertVideo;
    },
}
const actions = {
    GET_EXPERT_VIDEO_NUGGETS: (context) => {
        axios.get('/videosnippets')
            .then(response => {
                context.commit('SET_VIDEO_NUGGETS', response.data.items);
            })
    },
}

const getters = {
    EXPERT_VIDEO_NUGGETS: state => {
        return state.expertVideo;
    },
}
export default {
    state, getters, mutations, actions
}
