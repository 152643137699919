export default [
    {
        path: '/event/:eventid/booking',
        name: 'event-booking',
        component: () => import('@/views/events/booking/EventBooking'),
        meta: {
            resource: 'auth',
            breadcrumb: [{
                text: 'Events',
                to: '/events'
            },
                {
                    text: 'Book Event',
                    active: true
                },
            ],
            pageTitle: 'Events'
        },
    }
]


