import {testProp} from "leaflet/src/dom/DomUtil";

const state = {
    checkoutDetails: {
        invoiceaddress: null,
        paymentmethod: null,
    },
}
const mutations = {
    SET_CHECKOUT_ADDRESS: (state, address) => {
        state.checkoutDetails.invoiceaddress = address;
    },
    SET_PAYMENT_METHOD: (state, method) => {
        state.checkoutDetails.paymentmethod = method;
    },
}

const getters = {
    CHECKOUT_DETAILS: state => {
        return state.checkoutDetails;
    },
}
export default {
    state, getters, mutations
}
// testProp. asd as

