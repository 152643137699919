export default [
    {
        path: '/experts',
        name: 'experts-list',
        component: () => import('@/views/experts/ExpertsList'),
        meta: {
            resource: 'auth',
        },
    },
    {
        path: '/expert/:expertid/profile',
        name: 'expert-profile',
        component: () => import('@/views/experts/expert-single/profile/ExpertProfile.vue'),
        meta: {
            resource: 'auth',
            breadcrumb: [{
                text: 'Experts',
                to: '/experts'
            },
                {
                    text: 'Expert profile',
                    active: true
                },
            ],
            pageTitle: 'Expert profile'
        },
    },
    {
        path: '/experts/:expertid/details',
        name: 'experts-details',
        component: () => import('@/views/experts/expert-single/details/ExpertDetails.vue'),
        meta: {
            resource: 'auth',
            breadcrumb: [{
                text: 'Expert Details',
                to: '/experts'
            },
                {
                    text: 'Expert details',
                    active: true
                },
            ],
            pageTitle: 'Show Expert'
        },
    },
]


