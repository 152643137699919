import axios from "@/libs/axios";

const state = {
    invoiceAddresses: null,
    clientPaymentMethods: null,
    clientEvents: null,
    experts: null,
    singleExpert: null,
    singleExpertEvent: null,
    myBookings: [],
    bookingDetails:null,
    companyLogo:null,
    latest: [],
    pagination: {}
}

const mutations = {
    SET_INVOICE_ADDRESSES: (state, invoiceAddresses) => {
        state.invoiceAddresses = invoiceAddresses;
    },

    SET_CLIENT_PAYMENTS: (state, clientPaymentMethods) => {
        state.clientPaymentMethods = clientPaymentMethods;
    },

    SET_CLIENT_EVENTS: (state, clientEvents) => {
        state.clientEvents = clientEvents;
    },

    SET_EXPERTS: (state, experts) => {
        state.experts = experts;
    },
    SET_SINGLE_EXPERT: (state, singleExpert) => {
        state.singleExpert = singleExpert;
    },
    SET_SINGLE_EXPERT_EVENT: (state, singleExpertEvent) => {
        state.singleExpertEvent = singleExpertEvent;
    },

    SET_MY_BOOKINGS: (state, myBookings) => {
        state.myBookings = myBookings;
    },
    SET_BOOKING_DETAILS: (state, bookingDetails) => {
        state.bookingDetails = bookingDetails;
    },
    SET_COMPANY_LOGO(state, payload) {
        state.companyLogo = payload
    },
    SET_EVENTS_PAGINATION: (state, pagination) => {
        state.pagination = pagination;
    },
    SET_EXPERTS_PAGINATION: (state, pagination) => {
        state.pagination = pagination;
    },
    RESET_EVENTS (state) {
        // Merge rather than replace so we don't lose observers
        // https://github.com/vuejs/vuex/issues/1118
        state.clientEvents = null
    }
}
const actions = {
    GET_INVOICE_ADDRESSES: (context, payload) => {
        let url = `/client/invoiceaddresses`
        return axios.get(url).then(response => {
            context.commit('SET_INVOICE_ADDRESSES', response.data.items);
        })
    },


    ADD_INVOICE_ADDRESSES: (context, payload) => {
        let url = `/client/invoiceaddresses`
        return axios.post(url, payload)

    },


    GET_PAYMENT_METHODS: (context, payload) => {
        let url = `/client/paymentmethods`
        return axios.get(url, payload).then(response => {
            context.commit('SET_CLIENT_PAYMENTS', response.data.items);
        })

    },

    GET_EVENTS: (context,payload) => {
        let url = `/client/events`
        return axios.get(url, { params: { page: payload.page, search: payload.searchParams }}).then(response => {
            if (payload.page == 1) {
                state.latest = []
            }
            state.latest.push(...response.data.data)
            context.commit('SET_CLIENT_EVENTS', state.latest);
            let pagination = {
                'current_page': response.data.current_page,
                'last_page': response.data.last_page
            }
            context.commit('SET_EVENTS_PAGINATION', pagination);

        })
    },
    GET_EXPERTS: (context, payload) => {
        let url = `/client/experts`
        return axios.get(url, { params: { page: payload.page, search: payload.searchParams } }).then(response => {
            if (payload.page == 1) {
                state.latest = []
            }
            state.latest.push(...response.data.items.data)
            context.commit('SET_EXPERTS', state.latest);
            let pagination = {
                'current_page': response.data.items.current_page,
                'last_page': response.data.items.last_page
            }
            context.commit('SET_EXPERTS_PAGINATION', pagination);
        })
    },

    GET_SINGLE_EXPERT: (context, id) => {
        let url = `/client/experts/${id}`
        return axios.get(url).then(response => {
            context.commit('SET_SINGLE_EXPERT', response.data);
        })
    },

    GET_SINGLE_EXPERT_EVENT: (context, id) => {
        let url = `/client/events/${id}`
        return axios.get(url).then(response => {
            context.commit('SET_SINGLE_EXPERT_EVENT', response.data);
        })
    },

    GET_MY_BOOKINGS: (context, url) => {
        return axios.get(url).then(response => {
            context.commit('SET_MY_BOOKINGS', response.data);
        })
    },


    GET_BOOKING_DETAILS: (context, id) => {
        let url = `/client/booking/${id}`
        return axios.get(url).then(response => {
            context.commit('SET_BOOKING_DETAILS', response.data);
        })
    },


    BOOK_EVENT: (context, payload) => {
        return axios.post('/client/booking/event', payload)
    },

    GET_SINGLE_EXPERT_PROFILE: (context, id) => {
        let url = `/client/experts/${id}`
        return axios.get(url)
    },
    GET_COMPANY_LOGO:(context,val)=>{
        context.commit('SET_COMPANY_LOGO', val);
    },
    RESET_EVENTS ({ commit }) {
        commit('RESET_EVENTS')
    }
}

const getters = {
    INVOICE_ADDRESSES: state => {
        return state.invoiceAddresses;
    },
    PAYMENT_METHODS: state => {
        return state.clientPaymentMethods
    },
    CLIENT_EVENTS: state => {
        return state.clientEvents
    },
    EXPERTS: state => {
        return state.experts
    },
    SINGLE_EXPERT: state => {
        return state.singleExpert
    },
    SINGLE_EXPERT_EVENT: state => {
        return state.singleExpertEvent
    },
    MY_BOOKINGS: state => {
        return state.myBookings
    },

    BOOKING_DETAILS: state => {
        return state.bookingDetails
    },
    COMPANY_LOGO: state=>{
        return state.companyLogo
    },
    EVENTS_PAGINATION: state => {
        return state.pagination;
    },
    EXPERTS_PAGINATION: state => {
        return state.pagination;
    },


}
export default {
    state, getters, mutations, actions
}
