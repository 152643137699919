import axios from "@/libs/axios";


const state = {
    cart: {},
}

const mutations = {
    SET_CART: (state, cart) => {
        state.cart = cart;
    },
}
const actions = {
    GET_CART: (context) => {
        let url = `/shop/cart`
        axios.get(url)
            .then(response => {
                context.commit('SET_CART', response.data);
            })
    },
    DELETE_CART_ITEM: (context, id) => {
        let url = `/shop/cart/${id}`
        return axios.delete(url)
    },

    ADD_CART_ITEM: (context, payload) => {
        let url = `/shop/cart`
        return axios.post(url,payload)
    }


}

const getters = {
    CART: state => {
        return state.cart;
    },
}
export default {
    state, getters, mutations, actions
}
