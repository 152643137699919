export default [
  {
    path: '/company-profile',
    name: 'company-profile',
    component: () => import('@/views/admin/company-profile/CompanyProfile'),
    meta: {
      resource: 'auth',
      action:'manage',
      pageTitle: 'Company profile',
      breadcrumb: [
        {

          text: 'Company profile',
          active: true,
        },
      ],
    },
  }
]
