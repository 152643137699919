export default [
    {
        path: '/invoice-adress',
        name: 'invoice-adress-list',
        component: () => import('@/views/admin/invoice-adress/InvoiceAdressList.vue'),
        meta: {
            breadcrumb: [

                {
                    text: 'Invoice Adresses',
                    active: true
                }
            ],
            pageTitle: 'Invoice Adresses',
            navActiveLink: 'invoice-adress-list',
            resource: 'auth',
            action: 'manage',
        },
    },

    {
        path: '/invoice-adress/add',
        name: 'invoice-adress-add',
        component: () => import('@/views/admin/invoice-adress/InvoiceAdressAdd.vue'),
        meta: {
            resource: 'auth',
            action: 'manage',
            breadcrumb: [
                {
                    text: 'Invoice Adresses',
                    to: '/invoice',
                },
                {
                    text: 'Add Invoice Adress',
                    active: true
                }
            ],
            pageTitle: 'Add Invoice Adress',
            navActiveLink: 'invoice-add',
        },
    },
    {
        path: '/invoice-adress/:invoiceid/edit',
        name: 'invoice-adress-edit',
        component: () => import('@/views/admin/invoice-adress/InvoiceAdressEdit.vue'),
        meta: {
            resource: 'auth',
            action: 'manage',
            breadcrumb: [
                {
                    text: 'Invoice Adresses',
                    to: '/users',
                },
                {
                    text: 'Edit Invoice Adress',
                    active: true
                }
            ],
            pageTitle: 'Edit Invoice Adress',
            navActiveLink: 'Invoice',
        },
    },
]


