
export default {
  namespaced: true,

  state: {
    cart: [],


  },
  getters: {},

  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
    },

    pushProductToCart(state, credits) {
      state.cart.push({
        id: lastId + 1,
        productId: 1,
        qty: this.credits,
      })

    }
  },
  actions: {

    addToCart(state, credits) {
      context.commit('app-ecommerce/pushProductToCart', credits)

    },
  },
}
