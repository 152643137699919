export default [
    {
        path: '/users',
        name: 'users-list',
        component: () => import('@/views/admin/users/UsersList'),
        meta: {

            breadcrumb: [

                {
                    text: 'Users',
                    active: true
                }
            ],
            pageTitle: 'Users Overview',
            navActiveLink: 'users-list',
            resource: 'auth',
            action:'manage',
        },
    },

    {
        path: '/user/add',
        name: 'user-add',
        component: () => import('@/views/admin/users/UserAdd.vue'),
        meta: {
            resource: 'auth',
            action:'manage',
            breadcrumb: [
                {
                    text: 'Users',
                    to: '/users',
                },
                {
                    text: 'Add User',
                    active: true
                }
            ],
            pageTitle: 'Add User',
            navActiveLink: 'user-add',
        },
    },
    {
        path: '/user/:userid/edit',
        name: 'user-edit',
        component: () => import('@/views/admin/users/UserEdit.vue'),
        meta: {
            resource: 'auth',
            action:'manage',
            breadcrumb: [
                {
                    text: 'Users',
                    to: '/users',
                },
                {
                    text: 'Edit User',
                    active: true
                }
            ],
            pageTitle: 'Edit User',
            navActiveLink: 'Users',
        },
    },
]


