export default [
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/profile/Profile'),
        meta: {
            pageTitle: 'Profile',
            resource: 'auth',
            breadcrumb: [
                {
                    text: 'Profile',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'auth',
            action: 'read',
        },
    },
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/pages/authentication/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/register',
        name: 'auth-signup',
        component: () => import('@/views/pages/authentication/Register.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
        beforeEnter: (to, from, next) => {
            //Check if register is enabled
            if (process.env.VUE_APP_ENABLE_REGISTER === 'true') {
                next()
            } else {
                next(false)
            }
        }
    },
    {
        path: '/login/:email',
        name: 'auth-login-with-email',
        component: () => import('@/views/pages/authentication/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },

    {
        path: '/forgot-password',
        name: 'auth-forgot-password',
        component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },

    {
        path: '/resetpassword/:token/:email',
        name: 'auth-reset-password-v1',
        component: () => import('@/views/pages/authentication/ResetPassword-v1.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
        },
    },

    {
        path: '/miscellaneous/coming-soon',
        name: 'misc-coming-soon',
        component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/miscellaneous/not-authorized',
        name: 'misc-not-authorized',
        component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
    },
    {
        path: '/miscellaneous/under-maintenance',
        name: 'misc-under-maintenance',
        component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/miscellaneous/error',
        name: 'misc-error',
        component: () => import('@/views/pages/miscellaneous/Error.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/access-control',
        name: 'access-control',
        component: () => import('@/views/extensions/acl/AccessControl.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
        },
    },

    {
        path: '/notification-list',
        name: 'notification-list',
        component: () => import('@/views/pages/notifications/NotificationList.vue'),
        meta: {
            breadcrumb: [{
                text: 'Notifications List',
                to: '/apps/notifications',
                active: true
            }, ],
            pageTitle: 'Notifications List',
            navActiveLink: 'apps-notifications-list',
        },
    },

    {
        path: '/notification-preview',
        name: 'notification-preview',
        component: () => import('@/views/pages/notifications/NotificationPreview.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
        },
    },
    {
        path: '/email/verify/:id/:token',
        name: 'verify-email',
        component: () => import('@/views/pages/authentication/VerifyEmail.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import('@/views/faq/Faq.vue'),
        meta: {
            pageTitle: 'FAQ',
            breadcrumb: [{
                text: 'FAQ',
                active: true,
            },],
        },
    },

]
