
const state = {
    chatInterval: null,
}

const mutations = {
    CHAT_INTERVAL: (state, interval) => {
        state.chatInterval = interval;
    },
}

const actions = {
   
}

const getters = {
    GET_CHAT_INTERVAL: state => {
        return state.chatInterval
    },
}

export default {
    state, getters, mutations, actions
}
