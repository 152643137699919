<template>
  <div
      id="app"
      class="h-100"
      :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view/>
    </component>

    <scroll-to-top v-if="enableScrollToTop"/>
    <help :allowHelp="allowHelpFlag"></help>
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import {$themeColors, $themeBreakpoints, $themeConfig} from '@themeConfig'
import {provideToast} from 'vue-toastification/composition'
import {watch} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import {useWindowSize, useCssVar} from '@vueuse/core'

import store from '@/store'
import {mapActions} from "vuex";
import Help from '@/@core/components/help/help.vue'

import {isUserLoggedIn} from "@/auth/utils";
const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const LayoutEventRoom = () => import('@/layouts/event-room/LayoutEventRoom.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    LayoutEventRoom,

    ScrollToTop,
    Help
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change

  methods:{

    ...mapActions(['GET_AUTH_USER']),
  },
  watch: {
    $route(to) {
      if (to.name != 'event-room-show') {
        clearInterval(this.$store.state.chat.chatInterval)
      }
    }
  },

  computed: {
    layout() {
      if (this.$route.meta.layout === 'event-room'){
        $themeConfig.layout.type = 'event-room'
        return 'layout-event-room'
      }
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`

    },
    contentLayoutType() {

      return this.$store.state.appConfig.layout.type
    },
    allowHelpFlag(){
      return this.$route.name;
    }
  },

  beforeCreate() {

    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']



    for (let i = 0, len = colors.length; i < len; i++) {
        $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
      }
    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const {isRTL} = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
    //
    // this.$http.get("info")
    //     .then((response) => {
    //       document.title = response.data.name
    //       store.state.appConfig.general.appName = response.data.name
    //       store.state.appConfig.theme = response.data.colors
    //       store.state.appConfig.logo = response.data.logo
    //
    //       setTimeout(function () {
    //         const appLoading = document.getElementById('loading-bg')
    //         if (appLoading) {
    //           appLoading.style.display = 'none'
    //         }
    //       }, 200);
    //     })
    //     .catch(error => {
    //       console.debug("error")
    //       appLoading.style.display = 'none'
    //     });
    // setTimeout(function () {
    //   const appLoading = document.getElementById('loading-bg')
    //   if (appLoading) {
    //     appLoading.style.display = 'none'
    //   }
    // }, 500);


  },
  data() {
    return {
      body: "#000000",
    }
  },

  mounted() {
    this.GET_AUTH_USER()
    const isLoggedIn = isUserLoggedIn()
    if(isLoggedIn){
      let logo = localStorage.getItem('company_logo');
       this.$store.dispatch('GET_COMPANY_LOGO', logo)
      let colorsFromDb = JSON.parse(localStorage.getItem('userData')).client.colors;
      // store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { backgroundColor: colorsFromDb.topbar.background })
    }
    
    // document.documentElement.style.setProperty("$success", "#000000");
    // document.documentElement.style.setProperty('--color__primary', '#000000');

  },

  setup() {
    const {skin, skinClasses} = useAppConfig()
    const {enableScrollToTop} = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const {width: windowWidth} = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>