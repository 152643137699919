export default [

  {
    path: '/dashboard',
    name: 'dashboard-card-statistic',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      resource: 'auth',
    },
  },

]
