import axios from "@/libs/axios";

const state = {
    shopPaymentMethods: null
}

const actions = {
    STORE_SHOP_CHECKOUT: (context, payload) => {
        let url = `/shop/checkout`
        return axios.post(url, payload)
    },

    GET_SHOP_PAYMENT_METHODS: (context, payload) => {
        let url = `/shop/paymentmethods`
        return axios.get(url, payload).then(response => {
            context.commit('SET_SHOP_PAYMENT_METHODS', response.data.items);
        })

    },
}

const getters = {
    SHOP_PAYMENT_METHODS: state => {
        return state.shopPaymentMethods
    },
}

const mutations = {
    SET_SHOP_PAYMENT_METHODS: (state, shopPaymentMethods) => {
        state.shopPaymentMethods = shopPaymentMethods;
    }
}

export default {
    state, actions, getters, mutations
}
