export default [

    {
        path: '/credits',
        name: 'buy-credits',
        component: () => import('@/views/admin/credits/BuyCredits.vue'),
        meta: {
            breadcrumb: [{
                text: 'Credits',
                to: '/credits'
            },
                {
                    text: 'Buy credits',
                    active: true
                },
            ],
            navActiveLink: 'buy-credits',
            pageTitle: 'Buy credits'
        },
    },

    {
        path: '/cart',
        name: 'credits-details',
        component: () => import('@/views/admin/e-commerce/e-commerce-checkout/Cart.vue'),
        meta: {
            breadcrumb: [
              {
                text: 'Checkout',
                to: '/credits'
            },

            ],
            navActiveLink: 'Credits Details',
            pageTitle: 'Checkout'
        },
    },

    {
        path: '/distribute-credits',
        name: 'distribute-credits',
        component: () => import('@/views/admin/credits/DistributeCredits.vue'),
        meta: {
            breadcrumb: [{
                text: 'Credits',
                to: '/credits'
            },
                {
                    text: 'Distribute Credits',
                    active: true
                },
            ],
            pageTitle: 'Distribute Credits'
        },
    },

    {
        path: '/orders',
        name: 'buy-credits-list',
        component: () => import('@/views/admin/orders/OrdersList.vue'),
        meta: {
            breadcrumb: [{
                text: 'Credits',
                to: '/credits'
            },
                {
                    text: 'Buy Credits List',
                    active: true
                },
            ],
            pageTitle: 'Buy Credits List'
        },
    },

    {
        path: '/order/:id',
        name: 'single-order',
        component: () => import('@/views/admin/orders/OrderView.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Order',
                    active: true
                },
            ],
            pageTitle: 'Order'
        },
    },





]


