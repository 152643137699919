import Vue from 'vue'

Vue.mixin({
  methods: {
    getTableOptions() {
      return {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
})
