import axios from "@/libs/axios";
import { isUserLoggedIn } from "@/auth/utils";


const state = {
    authUser: null,
    companyLogo:null
}

const mutations = {
    SET_AUTH_USER: (state, user) => {
        state.authUser = user;
    },

    SET_COMPANY_LOGO: (state, url) => {
        state.companyLogo = url;
    }
}
const actions = {
    GET_AUTH_USER: (context) => {
        const isLoggedIn = isUserLoggedIn()
        let url = `/me`
        if (isLoggedIn) {
        axios.get(url)
            .then(response => {
                context.commit('SET_AUTH_USER', response.data);
            })
        }
    },
}

const getters = {
    AUTH_USER: state => {
        return state.authUser;
    },
}
export default {
    state, getters, mutations, actions
}
