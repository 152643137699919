export default [
  {
    path: '/invoicelist',
    name: 'invoice-list',
    component: () => import('@/views/admin/invoices/InvoiceList'),
    meta: {
      breadcrumb: [

        {
          text: 'Invoices',
          active: true
        }
      ],
      pageTitle: 'Invoices Overview',
      navActiveLink: 'invoice-list',
      resource: 'auth',
      action: 'manage',
    },
  },

  {
    path: '/invoice/:id/preview',
    name: 'invoice-preview',
    component: () => import('@/views/admin/invoices/InvoicePreview'),
    meta: {
      breadcrumb: [

        {
          text: 'Invoice Preview',
          active: true
        }
      ],
      pageTitle: 'Invoices Overview',
      navActiveLink: 'invoice-list',
      resource: 'auth',
      action: 'manage',
    },
  },

  {
    path: '/invoicepreview',
    name: 'invoice-preview',
    component: () => import('@/views/admin/invoices/InvoiceSidebarSendInvoice'),
    meta: {
      breadcrumb: [

        {
          text: 'Invoice Preview',
          active: true
        }
      ],

    },
  },
  ]



