import Vue from 'vue'
import VueI18n from 'vue-i18n'
import de from 'vee-validate/dist/locale/de.json';
import en from 'vee-validate/dist/locale/en.json';

import VeeValidate from 'vee-validate';
import {
  localize
} from 'vee-validate';


Vue.use(VueI18n)

localize('de', de, 'en', en);


function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
export default new VueI18n({
  locale: process.env.VUE_APP_LOCALE,
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),

})
