import axios from "@/libs/axios";

const state = {
    credits: null,
}

const mutations = {
    SET_CREDITS: (state, credits) => {
        state.credits = credits;
    },
}
const actions = {
    UPDATE_CREDITS: (context) => {
        axios.get('/info/credits')
            .then(response => {
                context.commit('SET_CREDITS', response.data.credit_amount);
            })
    },
}

const getters = {
    CREDITS: state => {
        return state.credits;
    },
}
export default {
    state, getters, mutations, actions
}
