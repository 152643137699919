import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import eCommerceStoreModule from '@/views/admin/e-commerce/eCommerceStoreModule'
import cart from "@/store/cart";
import checkout from "@/store/checkout";
import client from "@/store/client";
import shop from "@/store/shop/shop";
import singles_parts from "@/store/singles_parts";
import authUser from "@/store/user";
import profile from "@/store/profile";
import credits from "@/store/credits";
import chat from "@/store/chat";




Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        profile,
        credits,
        appConfig,
        shop,
        singles_parts,
        cart,
        authUser,
        client,
        checkout,
        verticalMenu,
        chat,
        'app-ecommerce': eCommerceStoreModule,
    },
    strict: process.env.DEV,
})
