export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth',
  },
  {
    action: 'read',
    subject: 'user',
  },
  {
    action: 'edit',
    subject: 'admin',
  },
  {
    action: 'manage',
    subject: 'auth',
  },
]

export const _ = undefined
